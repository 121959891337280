import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    bulk_items: {},
    sort: {
        COL_CAT: {
            column:"title",
            direction:"asc"
        },
        MAKE: {
            column:"title",
            direction:"asc"
        },
        POST: {
            column:"date_added",
            direction:"desc"
        }
    },
}

export const tablesSlice = createSlice({
    name: 'tables',
    initialState,
    reducers: {
        addBulkItem: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            const { table, item } = JSON.parse(action.payload)
            const update = { ...state.bulk_items }

            if ( !update[table] ) {
                update[table] = []
            }
            update[table] = [...update[table], item]

            state.bulk_items = update
        },
        removeBulkItem: (state, action) => {
            const { table, item } = JSON.parse(action.payload)

            if ( !state.bulk_items[table] ) {
                state.bulk_items[table] = []
            }
            state.bulk_items[table] = state.bulk_items[table].filter((el) => el !== item)
        },
        sortTable: (state, action) => {
            const { table, column, direction } = JSON.parse(action.payload)
            const sort = { ...state.sort }
            sort[table] = {
                column,
                direction,
            }

            state.sort = sort
        },
        resetTable: (state) => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    addBulkItem,
    removeBulkItem,
    sortTable,
    resetTable,
} = tablesSlice.actions

export default tablesSlice.reducer
