import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

export const myFirebase = initializeApp({
    apiKey: process.env.GATSBY_FS_API_KEY,
    databaseURL: process.env.GATSBY_FS_DB_URL,
    authDomain: process.env.GATSBY_FS_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FS_PROJECT_ID,
    storageBucket: process.env.GATSBY_FS_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FS_MESSAGE_SENDER_ID,
    appId: process.env.GATSBY_FS_APP_ID,
    measurementId: process.env.GATSBY_FS_MEASUREMENT_ID,
})
export const db = getFirestore()
