import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    details: {
        added: false,
        added_by: '',
        categories: [],
        excerpt: '',
        parent: '',
        published: false,
        slug: '',
        role: '',
        tags: [],
        title: '',
        type: '',
        updated: false,
        visibility: '',
    },
    doc: null,
    extras: {},
    media: {
        galleryDoc: [],
        galleryImage: [],
        galleryVideo: [],
        mediaSet: [],
    },
    pagebuilder: {
        id: null,
        isCollapsed: false,
        moduleKey: '',
        modules: [],
        nestedModules: {},
        settings: {},
        moduleSelect: {
            id: null,
            open: false
        },
    },
    profile: {
        id: null,
        active: false,
        name: '',
    }
}

export const pagebuilderSlice = createSlice({
    name: 'pagebuilder',
    initialState,
    reducers: {
        setPBDoc: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.doc = action.payload
        },
        setProfile: (state, action) => {
            const { id, active, name } = JSON.parse(action.payload)
            state.profile.id = id || state.profile.id
            state.profile.active = active === null ? state.profile.active : active
            state.profile.name = name === '' ? name : (name || state.profile.name)
        },
        setPageDetails: (state, action) => {
            state.details = { ...state.details, ...JSON.parse(action.payload) }
        },
        setPageExtras: (state, action) => {
            state.extras = { ...state.extras, ...JSON.parse(action.payload) }
        },
        setPageCharacteristics: (state, action) => {
            state.extras.characteristics = { ...state.extras.characteristics, ...JSON.parse(action.payload) }
        },
        setPageMedia: (state, action) => {
            state.media = { ...state.media, ...JSON.parse(action.payload) }
        },
        setModules: (state, action) => {
            state.pagebuilder.modules = action.payload
        },
        addNestedModule: (state, action) => {
            state.pagebuilder.nestedModules = { ...state.pagebuilder.nestedModules, ...JSON.parse(action.payload) }
        },
        collapseToggle: (state, action) => {
            state.pagebuilder.isCollapsed = action.payload
        },
        setSelectedToggle: (state, action) => {
            const { id, moduleKey } = JSON.parse(action.payload)
            state.pagebuilder.id = id
            state.pagebuilder.moduleKey = moduleKey
        },
        setSettings: (state, action) => {
            state.pagebuilder.settings = action.payload
        },
        setModuleSelect: (state, action) => {
            const { id, open } = JSON.parse(action.payload)
            state.pagebuilder.moduleSelect.id = id
            state.pagebuilder.moduleSelect.open = open
        },
        resetPageBuilder: (state) => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    setPBDoc,
    setProfile,
    setPageDetails,
    setPageExtras,
    setPageCharacteristics,
    setPageMedia,
    setModules,
    setModuleSelect,
    addNestedModule,
    collapseToggle,
    setSelectedToggle,
    resetPageBuilder,
    setSettings,
} = pagebuilderSlice.actions

export default pagebuilderSlice.reducer
