const fontSizes = [
    '8px',
    '9px',
    '12px',
    '13px',
    '14px',
    '15px',
    '16px',
    '21px',
    '23px',
    '29px',
]

fontSizes.xxs = fontSizes[0]
fontSizes.xs = fontSizes[1]
fontSizes.sm = fontSizes[2]
fontSizes.md = fontSizes[3]
fontSizes.lg = fontSizes[4]
fontSizes.xl = fontSizes[5]
fontSizes.xxl = fontSizes[6]
fontSizes.xxxl = fontSizes[7]

fontSizes.h1 = fontSizes[9]
fontSizes.h2 = fontSizes[8]
fontSizes.h3 = fontSizes[7]
fontSizes.h4 = fontSizes[6]
fontSizes.h5 = fontSizes[5]

export default fontSizes
