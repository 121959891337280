const buttons = {
    primary: {
        bg: 'red',
        cursor: 'pointer',
        color: 'white',
        borderRadius: 4,
        fontSize: 'md',
        fontWeight: 'regular',
        py: 2,
        px: 4,
        ':hover': {
            bg: 'black',
        },
    },
    primarySmall: {
        alignItems: 'center',
        bg: 'red',
        borderRadius: 4,
        cursor: 'pointer',
        color: 'white',
        display: 'flex',
        fontSize: 'small',
        fontWeight: 'regular',
        height: '33px',
        justifyContent: 'center',
        px: 4,
        ':hover': {
            bg: 'black',
        },
    },
    primaryTiny: {
        alignItems: 'center',
        bg: 'red',
        borderRadius: 4,
        cursor: 'pointer',
        color: 'white',
        display: 'flex',
        fontSize: 'xs',
        fontWeight: 'regular',
        height: '25px',
        justifyContent: 'center',
        px: 3,
        ':hover': {
            bg: 'black',
        },
    },
    secondary: {
        bg: 'white',
        border: '1px solid red',
        cursor: 'pointer',
        color: 'red',
        borderRadius: 4,
        fontSize: 'md',
        fontWeight: 'regular',
        py: 2,
        px: 4,
        ':hover': {
            border: '1px solid black',
            bg: 'black',
            color: 'white',
        },
    },
    secondarySmall: {
        alignItems: 'center',
        bg: 'white',
        border: '1px solid red',
        cursor: 'pointer',
        color: 'red',
        display: 'flex',
        borderRadius: 4,
        fontSize: 'small',
        fontWeight: 'regular',
        height: '33px',
        justifyContent: 'center',
        px: 4,
        ':hover': {
            border: '1px solid black',
            bg: 'black',
            color: 'white',
        },
    },
}

export default buttons
