import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components';
import theme from './src/theme/Theme';
import { GlobalStyle } from './src/theme/GlobalStyle';
import { myFirebase } from './src/system/firebase/index'
import { store } from './src/system/redux/store'

const Wrap = ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    // Initialise Firestore connection
    const initFirebase = myFirebase
    if ( initFirebase ) {
        // Firestore has been Initialised and connection successful
    }
    return <Provider store={store}>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            {element}
        </ThemeProvider>
    </Provider>
}

export default Wrap
