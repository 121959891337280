const colors = {
    black: '#000',
    charcoal: '#404040',
    orange: '#EF602F',
    red: '#CF1B2C',
    redOff: '#FFEBED',
    green: '#26A200',
    greenLime: '#C8E7BF',
    greenYellow: '#867B1D',
    greyDark: '#707070',
    greyLight: '#D9D9D9',
    greyLighter: '#B7B7B7',
    greyLightest: '#f2f2f2',
    greyMedium: '#868686',
    white: '#FFF',
    whiteOff: '#f8f8f8',
    salmon: '#E78D95',
    blue: '#1592E6',
    blueDeep: '#325981',
    blueLight: '#E3F0FB',
    yellowLight: '#F1E89E',
}

export default colors
