import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    id: '',
    modalType: '',
    collection: '',
    message: null,
    makesCollection: null,
    modelsCollection: null,
    slug: null
}

export const confirmboxSlice = createSlice({
    name: 'confirmbox',
    initialState,
    reducers: {
        confirmBoxToggle: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            const { isOpen, id, modalType, collection, message, makesCollection, modelsCollection, variantsCollection, slug } = JSON.parse(action.payload)

            state.isOpen = isOpen
            state.id = id
            state.modalType = modalType
            state.collection = collection
            state.message = message
            state.makesCollection = makesCollection
            state.modelsCollection = modelsCollection
            state.variantsCollection = variantsCollection
            state.slug = slug
        },
        confirmConfirmBox: (state) => {
            state.isOpen = false
        },
        resetConfirmBox: (state) => {
            state.isOpen = false
            state.id = ''
            state.modalType = ''
            state.collection = ''
            state.message = null
            state.makesCollection = null
            state.modelsCollection = null
            state.variantsCollection = null
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    confirmBoxToggle,
    confirmConfirmBox,
    resetConfirmBox,
} = confirmboxSlice.actions

export default confirmboxSlice.reducer
