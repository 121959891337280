// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leads-js": () => import("./../../../src/pages/leads.js" /* webpackChunkName: "component---src-pages-leads-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-pages-js": () => import("./../../../src/pages/pages.js" /* webpackChunkName: "component---src-pages-pages-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-reports-index-js": () => import("./../../../src/pages/reports/index.js" /* webpackChunkName: "component---src-pages-reports-index-js" */),
  "component---src-pages-settings-index-js": () => import("./../../../src/pages/settings/index.js" /* webpackChunkName: "component---src-pages-settings-index-js" */),
  "component---src-pages-settings-menus-js": () => import("./../../../src/pages/settings/menus.js" /* webpackChunkName: "component---src-pages-settings-menus-js" */),
  "component---src-pages-settings-support-js": () => import("./../../../src/pages/settings/support.js" /* webpackChunkName: "component---src-pages-settings-support-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */)
}

