import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {},
    category: false
}

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setPageData: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            state.data = JSON.parse(action.payload)
        },
        addPageData: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            state.data = { ...state.data, ...JSON.parse(action.payload) }
        },
        setCategoryData: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            state.category = JSON.parse(action.payload)
        },
        resetPageData: (state) => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    addPageData,
    setPageData,
    resetPageData,
    setCategoryData
} = pageSlice.actions

export default pageSlice.reducer
