const text = {
    h1: {
        fontWeight: 'regular',
        fontSize: 'h1',
    },
    h2: {
        fontWeight: 'regular',
        fontSize: 'h2',
    },
    h3: {
        fontWeight: 'regular',
        fontSize: 'h3',
    },
    h4: {
        fontWeight: 'regular',
        fontSize: 'h4',
    },
    h5: {
        fontWeight: 'regular',
        fontSize: 'h5',
    },
    body: {
        fontWeight: 'regular',
        fontSize: 'lg',
    },
    large: {
        fontWeight: 'bold',
        fontSize: 'xxl',
    },
    medium: {
        fontWeight: 'regular',
        fontSize: 'md',
    },
    small: {
        fontWeight: 'regular',
        fontSize: 'sm',
    },
    tiny: {
        fontWeight: 'regular',
        fontSize: 'xs',
    },
}

export default text
