import { configureStore } from '@reduxjs/toolkit'
import * as reducers from './reducers'

export const store = configureStore({
    reducer: {
        ...reducers, // this can sometimes have problems with "gatsby develop" caching all files (.cache) and not reloading them properly - may need to manually list them out
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),

})
