import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: true,
    isSetting: false,
    isHover: false,
    isRightOpen: false,
    menuItems: {}
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        sideBarToggle: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            state.isOpen = action.payload
        },
        sideBarSettingsToggle: (state, action) => {
            state.isSetting = action.payload
        },
        sideBarHoverToggle: (state, action) => {
            state.isHover = action.payload
        },
        sideBarRightToggle: (state, action) => {
            state.isRightOpen = action.payload
        },
        setMenuItems: (state, action) => {
            state.menuItems = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    sideBarToggle,
    sideBarSettingsToggle,
    sideBarHoverToggle,
    sideBarRightToggle,
    setMenuItems,
} = sidebarSlice.actions

export default sidebarSlice.reducer
