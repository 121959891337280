const fontWeights = [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800
]

fontWeights.regular = fontWeights[3];
fontWeights.medium = fontWeights[4];
fontWeights.semibold = fontWeights[5];
fontWeights.bold = fontWeights[6];

export default fontWeights