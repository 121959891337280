import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    list: [],
    lastVisible: false,
    refresh: false,
    filter: [],
    order: [],
    term: '',
    page: 0,
    nbPages: 0,
}

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        setList: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes

            const { list, lastVisible, filter, term, page, nbPages } = JSON.parse(action.payload)
            state.list = list
            state.lastVisible = lastVisible
            state.filter = filter
            state.term = term || ''
            state.page = page ?? 0
            state.nbPages = nbPages ?? 0
        },
        refreshList: (state) => {
            state.refresh = !state.refresh
        },
        setOrder: (state, action) => {
            const order = JSON.parse(action.payload)
            state.order = order[0]
        },
        resetList: (state) => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    setList,
    resetList,
    refreshList,
    setOrder,
} = listSlice.actions

export default listSlice.reducer
