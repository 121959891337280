const variants = {
    link: {
        color: 'black',
        ':hover': {
            color: 'red',
        },
    },
    linksRightSideBar: {
        color: 'white',
        textDecoration: 'none',
        fontWeight: 'regular',
        fontSize: 'lg',
    },
    card: {
        bg: 'white',
        boxShadow: 'card',
        borderRadius: 4,
    },
    collectionCard: {
        bg: 'white',
        borderRadius: 4,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'greyLight',
        padding: 'sm',
    },
    clientCard: {
        bg: 'whiteOff',
        borderRadius: 4,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'greyMedium',
        color: 'greyMedium',
        padding: 'xs',
    },
    clientCardSimplified: {
        bg: 'whiteOff',
        color: 'greyMedium',
    },
    borderBottom: {
        borderWidth: '0 0 4px 0',
        borderStyle: 'solid',
        borderColor: 'whiteOff',
    },
    curvedBox: {
        alignItems: 'center',
        bg: 'red',
        borderRadius: 4,
        cursor: 'pointer',
        color: 'white',
        display: 'flex',
        fontSize: 'sm',
        fontWeight: 'regular',
        justifyContent: 'center',
        px: 1,
        py: 1
    }
}

export default variants
