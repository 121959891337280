const forms = {
    input: {
        bg: 'whiteOff',
        color: 'greyMedium',
        fontWeight: 'medium',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'greyLighter',
    },
    inputSecondary: {
        bg: 'greyLight',
        color: 'greyMedium',
        fontWeight: 'medium',
        border: 0,
        borderRadius: 4,
    },
    contentEditableInput: {
        bg: 'transparent',
        border: 0,
        color: 'inherit',
        ':focus': {
            outline: 'none',
        },
    },
    searchInput: {
        bg: 'whiteOff',
        borderRadius: 4,
        border: 0,
        color: 'greyMedium',
        fontWeight: 'medium',
    },
    select: {
        bg: 'white',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'greyLighter',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
        paddingRight: '10',
    },
    textarea: {
        bg: 'white',
        color: 'greyMedium',
        fontWeight: 'medium',
        borderWidth: 1,
        borderRadius: 4,
        borderColor: 'greyLighter',
    },
    label: {},
    radio: {},
    checkbox: {},
}

export default forms
