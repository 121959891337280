import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    category: '',
    criteria: 'categories',
    module: '',
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setCategory: (state, action) => {
            const category = action.payload
            state.category = category
        },
        setCriteria: (state, action) => {
            const criteria = action.payload
            state.criteria = criteria
        },
        setModule: (state, action) => {
            const module = action.payload
            state.module = module
        },
        clearFilter: (state) => initialState,
    },
})

// Action creators are generated for each case reducer function
export const { 
    setCategory,
    setCriteria,
    setModule,
    clearFilter,
} = filterSlice.actions

export default filterSlice.reducer
