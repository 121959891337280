import { createGlobalStyle } from 'styled-components'
import theme from './Theme';

export const GlobalStyle = createGlobalStyle`
    html, body, #___gatsby, #gatsby-focus-wrapper {
        background-color: ${theme.colors.whiteOff};
        padding: 0;
        margin: 0;
        height: 100%;
    }
    body {
        color: ${theme.colors.charcoal};
        font-family: ${theme.fonts.body};
        font-size: ${theme.fontSizes.lg};
    }

    a {
        color: ${(props) => props.theme.colors.charcoal};
        font-weight: ${(props) => props.theme.fontWeights.semibold};
        text-decoration: none;
        &:hover {
            color: ${(props) => props.theme.colors.red};
        }
    }
    .tox .tox-notification--in {
        display: none !important;
    }

    .tox .tox-promotion {
        display: none !important;
    }
`;
